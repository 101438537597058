import "./scss-core/style.scss";

//DEV/SRC - JS GLOBAL
//import {bp} from "./js-global/variables-constants/breakpoints";
//import "./js-global/variables-constants/dom-elements";

//import "./js-global/functions/functions";

//import "./js-global/variables-constants/visitor";
//import "./js-global/site";

//DEV/SRC - TEMPLATE PARTS
//import "./template-parts/template-parts"; 

//THEME
//import "../wp-content/themes/natali-2/theme.es6";  

//SLIDERS INIT
//import './js-global/sliders-init/sliders-init';

//SPECIAL EFFECTS
//import './special-effects';

//ADDITIONS
//import './additions/additions';

//FINALS
import "./amendments";

//ENQUIRE JS BREAKPOINTS
//import './enquire-queries';

//PLUGINS
import '../wp-content/plugins/webevolvement-calculator-for-experiencesol/webevolvement-calculator-for-experiencesol.js';
